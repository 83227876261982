import React from 'react';
import './style.css';
import figmaLogo from './figma-logo.png';
import adobeXDLogo from './adobe-xd-logo.png';
import sketchLogo from './sketch-logo.png';
import adobeIllustrator  from './adobe-illustrator.png';

function Tool(props) {
  return (
    <div className="tool">
      <img src={props.src} alt={props.name} />
      <span>{props.name}</span>
    </div>
  )
}

function Skill(props) {
  return (
    <div className="skill">
      <svg width="9" height="9" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.46729" cy="7.5" r="7.5" fill="#1769FF"/>
      </svg>
      <span>{props.name}</span>
    </div>
  )
}

const TOOLS = [
  {
    src: figmaLogo,
    name: 'Figma'
  },
  {
    src: adobeXDLogo,
    name: 'Adobe XD'
  },
  {
    src: sketchLogo,
    name: 'Sketch'
  },
  {
    src: adobeIllustrator,
    name: 'Adobe Illustrator'
  }
];

const SKILLS = [
  "Sketching",
  "Wireframing",
  "Prototyping",
  "UI Design",
  "Interaction design",
  "Responsive web design",
  "Basic HTML, CSS, Javascript"
];

export default function Skills(props) {
  return (
    <div className="skills">
      <div className="skills__left-pane">
        <h3>Tools</h3>
        <div className="skills-left-pane__tools-wrapper">
          {
            TOOLS.map((tool, i) => <Tool key={i} {...tool} />)
          }
        </div>
        
      </div>
      <div className="skills__right-pane">
        <h3>Skills</h3>
        {
          SKILLS.map((skill, i) => <Skill key={i} name={skill}  />)
        }
      </div>
    </div>
  )
}
