import React from 'react';
import WorkCase from '../work-case/work-case';
import './style.css';

const WORKCASES = [
  {
    name: 'Landing page with interaction',
    videoId: "362020401",
    link: "https://www.behance.net/gallery/85903221/Landing-page-for-software-company"
  },
  {
    name: 'Mobile bank',
    videoId: "363119450",
    link: "https://www.behance.net/gallery/85903885/Mobile-bank-(redesign)"
  },
  {
    name: 'Online Marketplace (Android)',
    videoId: "363127693",
    link: "https://www.behance.net/gallery/85632117/prilozhenie-dlja-elektronnoj-torgovli"
  },
  {
    name: 'Calendar & Chat for organizations (iOS)',
    videoId: "363118589",
    link: "https://www.behance.net/gallery/85428457/Schedule-and-discuss-any-meetings"
  }
];

export default function Portfolio(props) {
  return (
    <div className="portfolio">
      <h3>Portfolio</h3>
      <div className="portfolio__cases">
      {
        WORKCASES.map((item, i) => <WorkCase key={i} {...item}  />)
      }
      </div>
    </div>
  );
}