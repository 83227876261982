import React from 'react';
import './App.css';
import Personal from './components/personal/personal';
import Skills from './components/skills/skills';
import Portfolio from './components/portfolio/portfolio';

function App() {

  return (
    <div className="App">
      <Personal />
      <Skills />
      <Portfolio />
    </div>
  );
}

export default App;
