import React from 'react';
import photo from './photo.png';
import behanceLogo from './behance-logo.png';
import "./style.css";

export default function Personal(props) {
  return (
    <div className="personal">
      <img src={photo} alt="aigerim" className="personal__photo" />
      <div className="personal__right-pan">
        <h1 className="personal-right-pan__name">Aigerim Imanalieva</h1>
        <h2 className="personal-right-pan__role">UI/UX Designer</h2>
        <a href="mailto: aigerim.imanalieva@gmail.com" className="personal-right-pan__email">aigerim.imanalieva@gmail.com</a>
        <span className="personal-right-pan__phone">+996705000781</span>
        <a href="https://www.behance.net/aigerimima063b" target="_blank" rel="noopener noreferrer" className="personal-right-pan__behance-btn">
          <img src={behanceLogo} alt="behance-logo" />
          <span>Open portfolio</span>
        </a>
      </div>
    </div>
  )
}
