import React from 'react';
import Video from '../video/video';
import './style.css';
import avatar from './avatar.png';


export default function WorkCase(props) {
  return (
    <a href={props.link} target="_blank" rel="noopener noreferrer" className="work-case-link"> 
      <div className="work-case">
        <div className="work-case__top" >
          <img className="work-case-top__avatar" src={ avatar } alt="avatar" />
          <span className="work-case-top__name">Aigerim Imanalieva</span>
        </div>
        <Video id={props.videoId} />
        <div className="work-case__bottom">
          <span>{props.name}</span>
          <div className="work-case-bottom__arrow" >
            <svg width="23" height="29" viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.53287 5.84746C2.68805 5.18657 2.65566 4.08846 3.46052 3.39476C4.26538 2.70105 5.60271 2.67446 6.44752 3.33535C6.44752 3.33535 19.4844 13.6921 19.52 13.7222C20.3531 14.4378 20.322 15.5726 19.4504 16.2567L6.44764 26.5812C5.60417 27.2433 4.26679 27.2185 3.46052 26.5259C2.65425 25.8333 2.6844 24.7351 3.52787 24.0731L14.3281 15.3492C14.5758 15.1491 14.5759 14.7715 14.3282 14.5713L3.53287 5.84746Z" fill="#4F4F4F"/>
            </svg>
          </div>
        </div>
      </div>
    </a>
  )
}