import React from 'react';

export default function Video(props) {
  const src = `https://player.vimeo.com/video/${props.id}?title=0&byline=0&portrait=0`;
  return (
    <div 
      style={{ 
        padding: '62.5% 0 0 0', 
        position: 'relative', 
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)', 
        borderTop: '1px solid rgba(0, 0, 0, 0.1)' 
      }}
    >
        <iframe 
          src={src}
          title={src}
          style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} 
          frameBorder="0" 
          allow="autoplay; fullscreen" 
          allowFullScreen
        />
    </div>
  )
}
